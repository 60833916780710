import React from "react"

import Home from "../components/Pages/Home"

import "../styles/styles.css"
import { graphql, StaticQuery } from "gatsby"

const indexQuery = graphql`
  query {
    headerImg: imageSharp(original: { src: { regex: "/.*header-image.*/" } }) {
      id
      fixed(height: 400, quality: 92) {
        ...GatsbyImageSharpFixed
      }
    }
    valuesImg: imageSharp(
      original: { src: { regex: "/.*home-rectangles.*/" } }
    ) {
      id
      fixed(height: 400, quality: 92) {
        ...GatsbyImageSharpFixed
      }
    }
    backgroundImg: imageSharp(
      original: { src: { regex: "/.*bg-kangaroo.*/" } }
    ) {
      id
      fixed(height: 800, quality: 92) {
        ...GatsbyImageSharpFixed
      }
    }
    markdownRemark(fields: { slug: { eq: "/pages/home/" } }) {
      frontmatter {
        title
        subtitle
        businessFeaturesSection {
          title
          subtitle
          features {
            title
            description
            icon {
              childImageSharp {
                fixed(height: 24, quality: 92) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        valuesSection {
          title
          description
        }
        technologySection {
          title
          description
          icons {
            name
            category
            icon {
              childImageSharp {
                fluid(maxHeight: 30, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        portfolioSection {
          title
          description
        }
        contactSection {
          title
          description
        }
        customersSection {
          title
          customers {
            name
            icon {
              childImageSharp {
                fluid(maxHeight: 50, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => (
  <StaticQuery
    query={indexQuery}
    render={data => {
      return (
        <Home
          {...props}
          page={data.markdownRemark.frontmatter}
          headerImg={data.headerImg}
          valuesImg={data.valuesImg}
          backgroundImg={data.backgroundImg}
        />
      )
    }}
  />
)

export default IndexPage
