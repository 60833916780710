import React from "react"
import styles from "./styles.module.css"

const TechSection = ({ title, techs }) => (
  <div className={`${styles.row} ${styles.techSection}`}>
    <div className={`${styles.col} ${styles.alignRight}`}>
      <h1>{title}</h1>
    </div>
    <div className={styles.separator}></div>
    <div className={styles.col}>
      {techs.map(tech => (
        <p key={tech.name}>{tech.name}</p>
      ))}
    </div>
  </div>
)

export default TechSection
